import styles from "@/components/Shared/DisplayDiscountPrice/index.module.scss"
import { NumericFormat } from "react-number-format"
const DisplayDiscountPrice = ({
  discountedPrice = 0,
  price = 0,
  currencySign = "$",
}) => {
  if (discountedPrice && discountedPrice !== price) {
    return (
      <div className={styles.discountprice}>
        <div className="price">
          <div className="price-discount">
            <NumericFormat
              value={discountedPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={currencySign}
              decimalScale={2}
              fixedDecimalScale={true}
            />
            <div className="discount">
              <NumericFormat
                value={price}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currencySign}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="original-price">
      <NumericFormat
        value={price}
        displayType={"text"}
        thousandSeparator={true}
        prefix={currencySign}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    </div>
  )
}

export default DisplayDiscountPrice
