import React, { useState, useEffect } from "react"
import { getConfig } from "@/constants/config"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import { NumericFormat } from "react-number-format"
import MissingImage from "@/public/images/image-missing.png"
import styles from "@/components/Shared/UpsellInstallServices/ParentProductCard/index.module.scss"
import _isEmpty from "lodash/isEmpty"

const ParentProductCard = ({
  productTitleUpSell = "",
  productImageUrl = MissingImage?.src ?? "",
  customerFacingSKU = "",
  name = "",
  quantity = 1,
  unitPrice = 0,
  currencySign = "$",
  outOfStockTitle = "",
  includeTitle = "",
  containerClassName = "",
  includedItems = {},
  availableQuantity = 0,
  checkChangeHandlerForParentProduct = () => {},
}) => {
  const [skuPrependName, setSkuPrependName] = useState("")
  useEffect(() => {
    checkSkuKPrepend()
  }, [])

  const checkChangeHandler = e => {
    const { target: { checked = false } = {} } = e ?? {}
    checkChangeHandlerForParentProduct(checked, customerFacingSKU)
  }
  const isChecked = !_isEmpty(includedItems)
    ? includedItems[customerFacingSKU]?.check
    : false

  const checkSkuKPrepend = async () => {
    const { apiEndpoints: { skuPrepend = "" } = {} } = (await getConfig()) ?? {}
    setSkuPrependName(skuPrepend)
  }

  return (
    <div className={styles.leftside}>
      <div className="productcard">
        <div className={`left-side${containerClassName}`}>
          <div className="head">{productTitleUpSell}</div>

          <div className="left-side--moblie">
            <div className="image">
              <img src={productImageUrl} alt={name} />
            </div>
            <div className="prouct-info--mobile">
              <div className="product-name">{name}</div>
              <div className="product-sku">
                {skuPrependName.toLowerCase() ===
                customerFacingSKU.substring(0, 2).toLowerCase()
                  ? customerFacingSKU
                  : `${
                      skuPrependName ? skuPrependName : ""
                    }${customerFacingSKU}`}
              </div>
              <div className="product-price">
                <NumericFormat
                  value={unitPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currencySign}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </div>
              <div className="product-qty">Qty: {quantity}</div>
              <div className="pdp-upsell">
                {!availableQuantity ? (
                  <div className="pdp-out-of-stock">{outOfStockTitle}</div>
                ) : (
                  <div className="pdp-include-div">
                    <div className="pdp-checkbox">
                      <Checkbox
                        checked={isChecked}
                        onChange={checkChangeHandler}
                      ></Checkbox>
                    </div>
                    {includeTitle}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParentProductCard
