import React, { useEffect, useState } from "react"
import Styles from "@/components/core/ToolTip/Tooltip.module.scss"

const ToolTip = props => {
  const [{ children = null, ...attr }, setAttribute] = useState({
    id: props.id,
    role: "tooltip",
    tabIndex: props.setFocus ? 0 : -1,
    "aria-label": props.ariaLabel,
    children: props.message,
  })
  useEffect(() => {
    setAttribute({ ...attr, children: props.message })
  }, [props.message])

  useEffect(() => {
    if (props.setFocus) document.getElementById(props.id)?.focus()
  }, [props.setFocus])

  return (
    <div className={Styles.tooltipWrapper}>
      <div className="tooltip__container" role="alert" aria-live="polite">
        {props.label && (
          <div>
            <span className="--label">{`${props.label}:`}</span>{" "}
          </div>
        )}
        <span {...attr}>{children}</span>
      </div>
    </div>
  )
}

export default ToolTip
