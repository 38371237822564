import React, { useState, useEffect } from "react"
import { getConfig } from "@/constants/config"
import QuantitySelector from "@/components/cart/QuantitySelector"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import useWindowResize from "@/hooks/useWindowResize"
import DisplayDiscountPrice from "@/components/Shared/DisplayDiscountPrice"
import { getPresetUrl } from "@/utils"
import Style from "@/components/Shared/UpsellInstallServices/RequiredProductItem/index.module.scss"
import { addAnalyticsForIncludeforRequiredItem } from "@/components/Shared/UpsellInstallServices/analytics"

const RequiredProductItem = ({
  persona = "GST",
  requiredProductItem = {},
  currencySign = "$",
  parsedConfigs = {},
  rowCols = 0,
  baseUrl = "",
  parentSku = "",
  includedItems = [],
  upSellDataIndex = -1,
  quantityChangeHanlder = () => {},
  updateRequiredItems = () => {},
  includeTitle = "",
  recommendationTitle = "",
  outOfStockTitle = "",
  showQtyInput = true,
  dynamicClass = "",
  index = 1,
  installServiceDataAvailable = false,
  cartProductData = {},
}) => {
  useEffect(() => {
    checkSkuKPrepend()
  }, [])

  const [skuPrependName, setSkuPrependName] = useState("")

  const [width] = useWindowResize()
  const {
    sku_s: skuId = "",
    ProductBrandName_s: productBrandName = "",
    productName_s: productName = "",
    [`priceList.${persona}.price_d`]: productPrice = 0,
    [`priceList.${persona}.discountedPrice_d`]: discountedPrice = 0,
    ["productImages.labelWithUrl_ss"]: productImage = [],
    availableQuantity = 0,
  } = requiredProductItem

  let imgId = ""
  if (productImage.length) {
    const index = productImage
      .map(item => (item.split("|")?.length ? item.split("|")[0] : ""))
      .indexOf(skuId)
    const assetId = productImage[index]?.split("|")?.length
      ? productImage[index].split("|")[1]
      : ""
    imgId = assetId
  }

  const requiredProdImgUrl = getPresetUrl(
    width,
    rowCols,
    baseUrl,
    imgId,
    parsedConfigs
  )
  const isChecked =
    includedItems[parentSku]?.includedItems[skuId]?.check ?? false
  const checkChangeHandler = (e, requiredProductItem) => {
    const { target: { checked = false } = {} } = e
    updateRequiredItems(checked, parentSku, skuId, upSellDataIndex)
    const qty = includedItems[parentSku]?.includedItems[skuId]?.quantity ?? 1
    addAnalyticsForIncludeforRequiredItem(
      checked,
      includeTitle,
      recommendationTitle,
      requiredProductItem,
      upSellDataIndex,
      installServiceDataAvailable ? index + 1 : index,
      persona,
      "",
      qty
    )
  }

  const checkSkuKPrepend = async () => {
    const { apiEndpoints: { skuPrepend = "" } = {} } = (await getConfig()) ?? {}
    setSkuPrependName(skuPrepend)
  }

  return (
    <div className={Style.requireditem}>
      <div className={`required-item-for-install-service${dynamicClass}`}>
        <div className="required-item-for-install-service__img">
          <img src={requiredProdImgUrl} alt={productBrandName} />
        </div>
        <div className="required-item-for-install-service__title">
          {productBrandName || productName}
        </div>
        <div className="required-item-for-install-service__sku">
          {skuPrependName.toLowerCase() === skuId.substring(0, 2).toLowerCase()
            ? skuId
            : `${skuPrependName ? skuPrependName : ""}${skuId}`}
        </div>
        <div className="required-item-for-install-service__price-quantity">
          <div className="required-item-for-install-service__price">
            <DisplayDiscountPrice
              discountedPrice={discountedPrice}
              price={productPrice}
              currencySign={currencySign}
            />
          </div>

          {showQtyInput && availableQuantity ? (
            <div className="required-item-for-install-service__quantity">
              <QuantitySelector
                size="small"
                source="cart-landing"
                value={1}
                onChange={(qty, action) =>
                  quantityChangeHanlder(
                    qty,
                    parentSku,
                    skuId,
                    upSellDataIndex,
                    action,
                    requiredProductItem,
                    index,
                    installServiceDataAvailable,
                    cartProductData
                  )
                }
              />
            </div>
          ) : null}
        </div>
        {!availableQuantity ? (
          <div className="not-in-stock">{outOfStockTitle}</div>
        ) : (
          <div className="required-item-for-install-service__include">
            <div className="include-div">
              <div className="checkbox">
                <Checkbox
                  checked={isChecked}
                  onChange={e => checkChangeHandler(e, requiredProductItem)}
                />
              </div>
              {includeTitle}
            </div>
          </div>
        )}
        {/* <div className="number-in-stock">
        <img src={TooltipIcon.src} alt="" />
        <div className="number-in-stock__msg">9 item(s) available to ship.</div>
      </div> */}
      </div>
    </div>
  )
}

export default RequiredProductItem
