export const BATHROOM_SERVICES = "bathroom design services"
export const FREE_MSG = "free kohler bathroom design plan consultation"
export const NAVIGATION = "navigation"
export const WAIT_TIME_FOR_LEAR_MORE = 5000
export const ADD_SERVICE = "add an install service"
export const TOILET_INSTALL = "toilet install service"
export const INSTALLATION_SERVICES = "installation services"
export const INSTALL_SERVCIE = "install service"
export const COMPLIMENTARY = "complimentary plan consultation"
export const ADD_TO_CART = "add to cart"
